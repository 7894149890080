<template>
<div>

  <!----- 搜索框 start------->
  <el-form :inline="true" class="search">
    <el-form-item>
      <el-button @click="getBalance()">查询</el-button>
    </el-form-item>
    <el-form-item style="float: right">
      <el-button type="primary" @click.stop="clickCreate()">添加池</el-button>
    </el-form-item>
  </el-form>
  <!----- 搜索框 end------->

  <el-table
      :data="tableData"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{children: 'children'}">
    <el-table-column prop="name" label="名称" />
    <el-table-column prop="balance" label="余额" />
    <el-table-column prop="balance_date" label="校准日期" />
    <el-table-column label="计算余额">
      <template #default="scope">
        <el-button
            type="info"
            size="small"
            v-if="!scope.row.all_balance"
            @click="updateBalance(scope.row)"
        >计算余额</el-button>
        <div v-else>{{ scope.row.all_balance }}</div>
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <el-button type="warning" size="small" @click="clickUpdate(scope.row)">修改</el-button>
      </template>
    </el-table-column>
  </el-table>

  <!-------- 弹出框表单 start ---------->
  <el-dialog v-model="form.show" title="编辑资金池" fullscreen append-to-body>
    <el-form
        ref="project.balance"
        :rules="form.rules"
        label-width="100px"
        :model="form.data">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.data.name" placeholder="输入名称" />
      </el-form-item>

      <el-form-item label="余额" prop="balance">
        <el-input type="number" v-model="form.data.balance" placeholder="输入预算总金额" />
      </el-form-item>

      <el-form-item label="校对日期" prop="balance_date">
          <el-date-picker
              v-model="form.data.balance_date"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择"
          />
      </el-form-item>

      <el-form-item label="规则">
        <el-button size="small" @click="addCConfig">添加规则</el-button>
      </el-form-item>

      <el-table :data="form.data.config">
        <el-table-column label="规则">
          <template #default="scope">
            <el-form-item  label-width="0px" :rules="[{
                required: true,
                message: '选择规则',
                trigger: 'blur',
              }]">
              <el-select v-model="scope.row.name">
                <el-option label="公司" value="company_id"></el-option>
                <el-option label="项目" value="project_id"></el-option>
                <el-option label="合同" value="project_contract_id"></el-option>
                <el-option label="支付类型" value="pay_type"></el-option>
                <el-option label="记录ID" value="id"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="符号">
          <template #default="scope">
            <el-form-item label-width="0px" :rules="[{
                required: true,
                message: '请选择',
                trigger: 'blur',
              }]">
              <el-select v-model="scope.row.type">
                <el-option label="等于" value="eq"></el-option>
                <el-option label="大于" value="gt"></el-option>
                <el-option label="大于等于" value="gte"></el-option>
                <el-option label="小于" value="lt"></el-option>
                <el-option label="小于等于" value="lte"></el-option>
                <el-option label="包含" value="in"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="值">
          <template #default="scope">
            <el-form-item  label-width="0px" :rules="[{
                required: true,
                message: '请填写',
                trigger: 'blur',
              }]">
              <el-input v-model="scope.row.value" placeholder="输入值"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="danger" @click="delConfig(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-form>

    <template #footer>
        <span class="dialog-footer">
          <el-button @click="form.show = false">取消</el-button>
          <el-button type="primary" @click="submit">提交</el-button>
        </span>
    </template>
  </el-dialog>
  <!-------- 弹出框表单 end ---------->
</div>
</template>

<script>
import {apiGetProjectBalance, apiCreateProjectBalance, apiUpdateProjectBalance, apiGetCalcProjectBalance} from '@/api/projectApi'
export default {
  created () {
    this.getBalance()
  },
  data () {
    return {
      tableData: [],
      form: {
        show: false,
        data: {
          id: 0,
          name: '',
          balance: '',
          balance_date: '',
          config: [],
        },
        rules: {
          name: [{required: true, message: '填写名称', trigger: 'blur',}],
          balance: [{required: true, message: '填写余额', trigger: 'blur',}],
          balance_date: [{required: true, message: '选择日期', trigger: 'blur',}],
        },
      }
    }
  },
  methods: {
    getBalance () {
      apiGetProjectBalance().then(data => {
        this.tableData = data
      })
    },
    clickCreate () {
      this.form.data.id = 0
      this.form.show = true
    },
    clickUpdate (row) {
      this.form.data = row
      this.form.show = true
    },
    addCConfig () {
      this.form.data.config.push({
        type: 'eq',
        name: '',
        value: '',
      })
      console.log(this.form.data.config)
    },
    delConfig (index) {
      this.form.data.config.splice(index, 1)
    },
    updateBalance (row) {
      apiGetCalcProjectBalance(row.id).then(res =>{
        row.all_balance = res.data
      })
    },
    submit () {
      this.$refs['project.balance'].validate(valid =>{
        if (valid) {
          if (this.form.data.id) {
            apiUpdateProjectBalance(this.form.data.id, this.form.data).then(() =>{
              this.$message.success('修改成功')
              this.getBalance()
              this.form.show = false
            })
          } else {
            apiCreateProjectBalance(this.form.data).then(() =>{
              this.$message.success('操作成功')
              this.getBalance()
              this.form.show = false
            })
          }

        }
      })
    }
  }
}
</script>
